import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto, sortByText } from '@nuis/common';
import { map, Observable, of } from 'rxjs';
import { AuditModel } from '../../audits';
import { AuditsDto, mapAuditsDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class AuditsApiService {
    private readonly translate = inject(TranslateService);
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public getAuditLogs(entityId: string, entityTypes: string[]): Observable<AuditModel[]> {
        return this.http
            .get<AuditsDto>(`${this.config.baseUrl}Audit/GetAuditLogs`, {
                params: {
                    entityId: entityId,
                    entityTypes: entityTypes,
                },
            })
            .pipe(map(mapAuditsDto));
    }

    public getAuditFields(): Observable<OverviewDto[]> {
        const fields: OverviewDto[] = [
            { key: 'ContractId', name: this.translate.instant('auditField.ContractId'), color: null },
            { key: 'PolicyNumber', name: this.translate.instant('auditField.PolicyNumber'), color: null },
            { key: 'CancellationReason', name: this.translate.instant('auditField.CancellationReason'), color: null },
            {
                key: 'CancellationReceived',
                name: this.translate.instant('auditField.CancellationReceived'),
                color: null,
            },
            { key: 'ContractTypeId', name: this.translate.instant('auditField.ContractTypeId'), color: null },
            { key: 'CoverTo', name: this.translate.instant('auditField.CoverTo'), color: null },
            { key: 'CoverFrom', name: this.translate.instant('auditField.CoverFrom'), color: null },
            { key: 'Created', name: this.translate.instant('auditField.Created'), color: null },
            { key: 'Discriminiator', name: this.translate.instant('auditField.Discriminiator'), color: null },
            { key: 'Discount', name: this.translate.instant('auditField.Discount'), color: null },
            { key: 'LapsedOn', name: this.translate.instant('auditField.LapsedOn'), color: null },
            { key: 'LastModified', name: this.translate.instant('auditField.LastModified'), color: null },
            { key: 'MinimumTermEndsOn', name: this.translate.instant('auditField.MinimumTermEndsOn'), color: null },
            { key: 'PartnerKey', name: this.translate.instant('auditField.PartnerKey'), color: null },
            { key: 'Premium', name: this.translate.instant('auditField.Premium'), color: null },
            { key: 'PromotionCode', name: this.translate.instant('auditField.PromotionCode'), color: null },
            { key: 'Status', name: this.translate.instant('auditField.Status'), color: null },
            { key: 'SubscriptionDate', name: this.translate.instant('auditField.SubscriptionDate'), color: null },
            { key: 'TerminationOn', name: this.translate.instant('auditField.TerminationOn'), color: null },
            { key: 'TrackingCode', name: this.translate.instant('auditField.TrackingCode'), color: null },
            { key: 'UserId', name: this.translate.instant('auditField.UserId'), color: null },
            { key: 'VerificationMethod', name: this.translate.instant('auditField.VerificationMethod'), color: null },
            { key: 'InsuredRisk', name: this.translate.instant('auditField.InsuredRisk'), color: null },
            {
                key: 'AdditionalInformation_CaseId',
                name: this.translate.instant('auditField.AdditionalInformation_CaseId'),
                color: null,
            },
            {
                key: 'AdditionalInformation_RetailerId',
                name: this.translate.instant('auditField.AdditionalInformation_RetailerId'),
                color: null,
            },
            {
                key: 'AdditionalInformation_SalesPerson',
                name: this.translate.instant('auditField.AdditionalInformation_SalesPerson'),
                color: null,
            },
            {
                key: 'AdditionalInformation_TariffId',
                name: this.translate.instant('auditField.AdditionalInformation_TariffId'),
                color: null,
            },
            {
                key: 'AdditionalInformation_TariffName',
                name: this.translate.instant('auditField.AdditionalInformation_TariffName'),
                color: null,
            },
            { key: 'DateOfBirth', name: this.translate.instant('auditField.DateOfBirth'), color: null },
            { key: 'FirstName', name: this.translate.instant('auditField.FirstName'), color: null },
            { key: 'LastName', name: this.translate.instant('auditField.LastName'), color: null },
            { key: 'Gender', name: this.translate.instant('auditField.Gender'), color: null },
            {
                key: 'MainInsuredPerson_TitleSuffix',
                name: this.translate.instant('auditField.MainInsuredPerson_TitleSuffix'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_TitlePrefix',
                name: this.translate.instant('auditField.MainInsuredPerson_TitlePrefix'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_DateOfBirth',
                name: this.translate.instant('auditField.MainInsuredPerson_DateOfBirth'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Email',
                name: this.translate.instant('auditField.MainInsuredPerson_Email'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_FirstName',
                name: this.translate.instant('auditField.MainInsuredPerson_FirstName'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Gender',
                name: this.translate.instant('auditField.MainInsuredPerson_Gender'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_LastName',
                name: this.translate.instant('auditField.MainInsuredPerson_LastName'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_NationalIdentificationNumber',
                name: this.translate.instant('auditField.MainInsuredPerson_NationalIdentificationNumber'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_PhoneNumber',
                name: this.translate.instant('auditField.MainInsuredPerson_PhoneNumber'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Address_AddressLine1',
                name: this.translate.instant('auditField.MainInsuredPerson_Address_AddressLine1'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Address_AddressLine2',
                name: this.translate.instant('auditField.MainInsuredPerson_Address_AddressLine2'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Address_City',
                name: this.translate.instant('auditField.MainInsuredPerson_Address_City'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Address_Country',
                name: this.translate.instant('auditField.MainInsuredPerson_Address_Country'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Address_PostalCode',
                name: this.translate.instant('auditField.MainInsuredPerson_Address_PostalCode'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_PersonType',
                name: this.translate.instant('auditField.MainInsuredPerson_PersonType'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_PassportNumber',
                name: this.translate.instant('auditField.MainInsuredPerson_PassportNumber'),
                color: null,
            },
            {
                key: 'MainInsuredPerson_Id',
                name: this.translate.instant('auditField.MainInsuredPerson_Id'),
                color: null,
            },
            {
                key: 'NationalIdentificationNumber',
                name: this.translate.instant('auditField.NationalIdentificationNumber'),
                color: null,
            },
            {
                key: 'PassportIssuingAuthority',
                name: this.translate.instant('auditField.PassportIssuingAuthority'),
                color: null,
            },
            { key: 'PassportNumber', name: this.translate.instant('auditField.PassportNumber'), color: null },
            {
                key: 'PassportPlaceOfIssue',
                name: this.translate.instant('auditField.PassportPlaceOfIssue'),
                color: null,
            },
            { key: 'Identifier', name: this.translate.instant('auditField.Identifier'), color: null },
            { key: 'Indicator', name: this.translate.instant('auditField.Indicator'), color: null },
            { key: 'Contractid', name: this.translate.instant('auditField.Contractid'), color: null },
            { key: 'Date', name: this.translate.instant('auditField.Date'), color: null },
            { key: 'LastRoamingInfo', name: this.translate.instant('auditField.LastRoamingInfo'), color: null },
            { key: 'LeftHomeCountry', name: this.translate.instant('auditField.LeftHomeCountry'), color: null },
            {
                key: 'AdditionalInformation',
                name: this.translate.instant('auditField.AdditionalInformation'),
                color: null,
            },
            { key: 'CreatedBy', name: this.translate.instant('auditField.CreatedBy'), color: null },
            { key: 'LastModifiedBy', name: this.translate.instant('auditField.LastModifiedBy'), color: null },
            { key: 'Discount', name: this.translate.instant('auditField.Discount'), color: null },
            { key: 'id', name: this.translate.instant('auditField.id'), color: null },
            {
                key: 'AdditionalInsuredPersons',
                name: this.translate.instant('auditField.AdditionalInsuredPersons'),
                color: null,
            },
            { key: 'TravelState', name: this.translate.instant('auditField.TravelState'), color: null },
            { key: 'TravelSubState', name: this.translate.instant('auditField.TravelSubState'), color: null },
            { key: 'InsuranceType', name: this.translate.instant('auditField.InsuranceType'), color: null },
            { key: 'PaymentState', name: this.translate.instant('auditField.PaymentState'), color: null },
            { key: 'Name', name: this.translate.instant('auditField.Name'), color: null },
            { key: 'PartnerId', name: this.translate.instant('auditField.PartnerId'), color: null },
            { key: 'ParnterId', name: this.translate.instant('auditField.ParnterId'), color: null },
            { key: 'StartedOn', name: this.translate.instant('auditField.StartedOn'), color: null },
            { key: 'InsuranceStart', name: this.translate.instant('auditField.InsuranceStart'), color: null },
            { key: 'EndsOn', name: this.translate.instant('auditField.EndsOn'), color: null },
            { key: 'DeactivatedOn', name: this.translate.instant('auditField.DeactivatedOn'), color: null },
            { key: 'DeactivationSource', name: this.translate.instant('auditField.DeactivationSource'), color: null },
            { key: 'EndReason', name: this.translate.instant('auditField.EndReason'), color: null },
            { key: 'Countries', name: this.translate.instant('auditField.Countries'), color: null },
            { key: 'TicketId', name: this.translate.instant('auditField.TicketId'), color: null },
            {
                key: 'EstimatedAdditionalCosts',
                name: this.translate.instant('auditField.EstimatedAdditionalCosts'),
                color: null,
            },
            { key: 'EstimatedCosts', name: this.translate.instant('auditField.EstimatedCosts'), color: null },
            { key: 'ActualCosts', name: this.translate.instant('auditField.ActualCosts'), color: null },
            {
                key: 'ActualAdditionalCosts',
                name: this.translate.instant('auditField.ActualAdditionalCosts'),
                color: null,
            },
            { key: 'Description', name: this.translate.instant('auditField.Description'), color: null },
            { key: 'State', name: this.translate.instant('auditField.State'), color: null },
            { key: 'ClaimNumber', name: this.translate.instant('auditField.ClaimNumber'), color: null },
            { key: 'CLaimOn', name: this.translate.instant('auditField.CLaimOn'), color: null },
            { key: 'Additional', name: this.translate.instant('auditField.Additional'), color: null },
            { key: 'LossEvent_Name', name: this.translate.instant('auditField.LossEvent_Name'), color: null },
            { key: 'LossType_Name', name: this.translate.instant('auditField.LossType_Name'), color: null },
            { key: 'Caller_FirstName', name: this.translate.instant('auditField.Caller_FirstName'), color: null },
            { key: 'Caller_LastName', name: this.translate.instant('auditField.Caller_LastName'), color: null },
            { key: 'Caller_Title', name: this.translate.instant('auditField.Caller_Title'), color: null },
            { key: 'Caller_Gender', name: this.translate.instant('auditField.Caller_Gender'), color: null },
            { key: 'Caller_Email', name: this.translate.instant('auditField.Caller_Email'), color: null },
            { key: 'Caller_PhoneNumber', name: this.translate.instant('auditField.Caller_PhoneNumber'), color: null },
            {
                key: 'ClaimPerson_FirstName',
                name: this.translate.instant('auditField.ClaimPerson_FirstName'),
                color: null,
            },
            {
                key: 'ClaimPerson_LastName',
                name: this.translate.instant('auditField.ClaimPerson_LastName'),
                color: null,
            },
            { key: 'ClaimPerson_Title', name: this.translate.instant('auditField.ClaimPerson_Title'), color: null },
            { key: 'ClaimPerson_Gender', name: this.translate.instant('auditField.ClaimPerson_Gender'), color: null },
            { key: 'ClaimPerson_Email', name: this.translate.instant('auditField.ClaimPerson_Email'), color: null },
            {
                key: 'ClaimPerson_DateOfBirth',
                name: this.translate.instant('auditField.ClaimPerson_DateOfBirth'),
                color: null,
            },
            {
                key: 'ClaimPerson_NationalIdentificationNumber',
                name: this.translate.instant('auditField.ClaimPerson_NationalIdentificationNumber'),
                color: null,
            },
            {
                key: 'ClaimPerson_PhoneNumber',
                name: this.translate.instant('auditField.ClaimPerson_PhoneNumber'),
                color: null,
            },
            {
                key: 'ClaimPerson_City',
                name: this.translate.instant('auditField.ClaimPerson_City'),
                color: null,
            },
            {
                key: 'ClaimPerson_Country',
                name: this.translate.instant('auditField.ClaimPerson_Country'),
                color: null,
            },
            {
                key: 'ClaimPerson_PostalCode',
                name: this.translate.instant('auditField.ClaimPerson_PostalCode'),
                color: null,
            },
            {
                key: 'ClaimPerson_AddressLine1',
                name: this.translate.instant('auditField.ClaimPerson_AddressLine1'),
                color: null,
            },
            { key: 'AssisteurNumber', name: this.translate.instant('auditField.AssisteurNumber'), color: null },
            { key: 'LossEvent_Id', name: this.translate.instant('auditField.LossEvent_Id'), color: null },
            { key: 'LossType_Id', name: this.translate.instant('auditField.LossType_Id'), color: null },
            { key: 'EndorsementNumber', name: this.translate.instant('auditField.EndorsementNumber'), color: null },
            { key: 'Transmitter', name: this.translate.instant('auditField.Transmitter'), color: null },
            { key: 'ExecutedAgentEmail', name: this.translate.instant('auditField.ExecutedAgentEmail'), color: null },
            { key: 'CancelationReceived', name: this.translate.instant('auditField.CancelationReceived'), color: null },
            { key: 'CancelationReason', name: this.translate.instant('auditField.CancelationReason'), color: null },
            {
                key: 'CancelationProcessedOn',
                name: this.translate.instant('auditField.CancelationProcessedOn'),
                color: null,
            },
            { key: 'CreatedOn', name: this.translate.instant('auditField.CreatedOn'), color: null },
            { key: 'ModifiedOn', name: this.translate.instant('auditField.ModifiedOn'), color: null },
            { key: 'RefId', name: this.translate.instant('auditField.RefId'), color: null },
            { key: 'RefName', name: this.translate.instant('auditField.RefName'), color: null },
            { key: 'PaymentDoneOn', name: this.translate.instant('auditField.PaymentDoneOn'), color: null },
            { key: 'PostalCode', name: this.translate.instant('auditField.PostalCode'), color: null },
            { key: 'Addressline1', name: this.translate.instant('auditField.Addressline1'), color: null },
            { key: 'Addressline2', name: this.translate.instant('auditField.Addressline2'), color: null },
            { key: 'City', name: this.translate.instant('auditField.City'), color: null },
            { key: 'CompanyName', name: this.translate.instant('auditField.CompanyName'), color: null },
            { key: 'Country', name: this.translate.instant('auditField.Country'), color: null },
            { key: 'PartnerReference', name: this.translate.instant('auditField.PartnerReference'), color: null },
            { key: 'PhoneNumber', name: this.translate.instant('auditField.PhoneNumber'), color: null },
            { key: 'TitlePrefix', name: this.translate.instant('auditField.TitlePrefix'), color: null },
            { key: 'TitleSuffix', name: this.translate.instant('auditField.TitleSuffix'), color: null },
        ];

        return of(fields.sort((a, b) => sortByText(a.name, b.name, 1)));
    }
}
