import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, Reference } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { firstValueFrom, Observable, of } from 'rxjs';
import {
    mapOpenReversalDto,
    mapTransactionDto,
    OpenReversalDto,
    OverviewDto,
    QueryResultDto,
    SendReversalsRequestDto,
    TransactionDto,
    TransactionReversalRequestDto,
    TransactionReversalResponseDto,
} from '../../dtos';
import { ProductCategory } from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class TransactionApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getTransactions(contractId: string): Promise<TransactionDto[]> {
        const transactions = await firstValueFrom(
            this.http.get<TransactionDto[]>(`${this.config.baseUrl}Transactions/ListByContractId`, {
                params: { contractId: contractId },
            }),
        );

        return transactions.map((t) => mapTransactionDto(t));
    }

    public async getOpenReversals(): Promise<OpenReversalDto[]> {
        const result = await firstValueFrom(
            this.http.get<QueryResultDto<OpenReversalDto>>(`${this.config.baseUrl}Transactions/QueryOpenReversals`),
        );

        return result.records.map((s) => mapOpenReversalDto(s));
    }

    public async sendApprovals(productCategory: ProductCategory, body: SendReversalsRequestDto[]): Promise<void> {
        return await firstValueFrom(
            this.http.post(
                `${this.config.baseUrl}Transactions/ApproveReversalRequests?productCategory=${productCategory}`,
                {
                    transactions: body,
                },
            ),
        );
    }

    public async sendDenied(transactionIds: string[]): Promise<void> {
        return await firstValueFrom(
            this.http.post(`${this.config.baseUrl}Transactions/RejectReversalRequests`, {
                transactionIds: transactionIds,
            }),
        );
    }

    public async getTransactionsByReference(reference: Reference): Promise<TransactionDto[]> {
        const transactions = await firstValueFrom(
            this.http.get<TransactionDto[]>(`${this.config.baseUrl}Transactions/GetAllByRef`, {
                params: { ...reference },
            }),
        );

        return transactions.map((transaction) => {
            return {
                ...transaction,
                createdOn:
                    transaction.createdOn != null ? DateTime.fromISO(transaction.createdOn as never as string) : null,
                start: transaction.start != null ? DateTime.fromISO(transaction.start as never as string) : null,
                end: transaction.end != null ? DateTime.fromISO(transaction.end as never as string) : null,
            };
        });
    }

    public async createReversal(request: TransactionReversalRequestDto): Promise<TransactionReversalResponseDto> {
        return await firstValueFrom(
            this.http.post<TransactionReversalResponseDto>(
                `${this.config.baseUrl}transactions/CreateReversalRequests`,
                request,
            ),
        );
    }

    public async getTransactionTypes(): Promise<OverviewDto[]> {
        const transactionTypes: OverviewDto[] = [
            { key: 'Credit', name: this.translate.instant('transactionTypes.credit'), color: null },
            { key: 'Debit', name: this.translate.instant('transactionTypes.debit'), color: null },
            { key: 'Reversal', name: this.translate.instant('transactionTypes.reversal'), color: null },
        ];

        return transactionTypes;
    }

    public async getReversalStatuses(): Promise<OverviewDto[]> {
        const reversalStatuses: OverviewDto[] = [
            { key: 'Requested', name: this.translate.instant('reversal.status.requested'), color: null },
            { key: 'Approved', name: this.translate.instant('reversal.status.approved'), color: null },
            { key: 'Denied', name: this.translate.instant('reversal.status.denied'), color: null },
            { key: 'Finished', name: this.translate.instant('reversal.status.finished'), color: null },
        ];

        return reversalStatuses;
    }

    public getTransactionStates(): Observable<OverviewDto[]> {
        const transactionStates: OverviewDto[] = [
            { key: 'Open', name: this.translate.instant('paymentStates.open'), color: 'gray' },
            { key: 'Ok', name: this.translate.instant('paymentStates.ok'), color: 'green' },
            { key: 'Paid', name: this.translate.instant('paymentStates.paid'), color: 'green' },
            { key: 'Billed', name: this.translate.instant('paymentStates.billed'), color: 'green' },
            { key: 'Done', name: this.translate.instant('paymentStates.done'), color: 'green' },
            { key: 'Refunded', name: this.translate.instant('paymentStates.refunded'), color: 'green' },
            { key: 'Previously', name: this.translate.instant('paymentStates.previously'), color: 'gray' },
            { key: 'Notbilledyet', name: this.translate.instant('paymentStates.notbilledyet'), color: 'gray' },
            { key: 'NotOk', name: this.translate.instant('paymentStates.notok'), color: 'yellow' },
            { key: 'NotPaid', name: this.translate.instant('paymentStates.notpaid'), color: 'yellow' },
            { key: 'Notbilled', name: this.translate.instant('paymentStates.notbilled'), color: 'yellow' },
            { key: 'Inprogress', name: this.translate.instant('paymentStates.inprogress'), color: 'yellow' },
            { key: 'Pending', name: this.translate.instant('paymentStates.pending'), color: 'yellow' },
            {
                key: 'NoDueToTravelWarning',
                name: this.translate.instant('paymentStates.noDueToTravelWarning'),
                color: 'yellow',
            },
            { key: 'Locked', name: this.translate.instant('paymentStates.locked'), color: 'orange' },
            { key: 'Failed', name: this.translate.instant('paymentStates.failed'), color: 'red' },
            { key: 'Cancelled', name: this.translate.instant('paymentStates.cancelled'), color: 'red' },
        ];

        return of(transactionStates);
    }

    public async getReversalReasons(): Promise<OverviewDto[]> {
        const reversalReasons: OverviewDto[] = [
            {
                key: 'ContractCancellation',
                name: this.translate.instant('reversal.reason.contractcancellation'),
                color: null,
            },
            {
                key: 'GoodwillOfInsurer',
                name: this.translate.instant('reversal.reason.goodwillofinsurer'),
                color: null,
            },
            {
                key: 'ServiceNotConsumed',
                name: this.translate.instant('reversal.reason.servicenotconsumed'),
                color: null,
            },
            { key: 'TechnicalProblem', name: this.translate.instant('reversal.reason.technicalproblem'), color: 'red' },
            {
                key: 'AdministrativeError',
                name: this.translate.instant('reversal.reason.administrativeerror'),
                color: null,
            },
        ];

        return reversalReasons;
    }

    public getBankTypes(): Observable<OverviewDto[]> {
        const bankTypes: OverviewDto[] = [
            { key: 'bank', name: this.translate.instant('bankAccountTypes.bank'), color: null },
        ];

        return of(bankTypes);
    }
}
