import { FormControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';

export interface AuditsFilterForm {
    fields: FormControl<string[] | null>;
}

export function initAuditsFilterForm(fb: NonNullableFormBuilder): FormGroup<AuditsFilterForm> {
    return fb.group<AuditsFilterForm>({
        fields: fb.control(null),
    });
}
