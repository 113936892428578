export function getDateUtc(date: Date): Date {
    if (!date) {
        return date;
    }

    date = new Date(date?.toUTCString());
    return new Date(Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate()));
}

export function isPast(date: Date): boolean {
    const currentDate: Date = new Date();
    if (date != null) {
        return currentDate.getTime() - new Date(date)?.getTime() >= 0;
    } else {
        return false;
    }
}

export function isValidJMBG(jmbg: string): boolean {
    if (jmbg.length === 0) {
        return true;
    }
    if (jmbg.length === 13) {
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const arrJMBG = jmbg.split('');
        //check_birthyear
        const birthYear = arrJMBG.slice(4, 7).join('');
        let birthYearInt = Number(birthYear);
        if (birthYear[0] === '0') {
            birthYearInt = Number(birthYear) + 2000;
        } else if (birthYear[0] === '9') {
            birthYearInt = Number(birthYear) + 1000;
        } else {
            return false;
        }
        //check month
        const birthMonth = arrJMBG.slice(2, 4).join('');
        const birthMonthInt = Number(birthMonth);
        if (birthMonthInt > 12 || birthMonthInt < 1) {
            return false;
        }
        //check leap year and set number of days in february
        if (birthYearInt % 4 === 0 && (birthYearInt % 100 !== 0 || birthYearInt % 400 === 0)) {
            daysInMonth[1] = 29;
        }

        //check if birth date is valid
        const birthDate = arrJMBG.slice(0, 2).join('');
        const birthDateInt = Number(birthDate);
        if (birthDateInt > daysInMonth[birthMonthInt - 1] || birthDateInt < 1) {
            return false;
        }

        //check control number
        let controlNumber =
            11 -
            ((7 * (Number(arrJMBG[0]) + Number(arrJMBG[6])) +
                6 * (Number(arrJMBG[1]) + Number(arrJMBG[7])) +
                5 * (Number(arrJMBG[2]) + Number(arrJMBG[8])) +
                4 * (Number(arrJMBG[3]) + Number(arrJMBG[9])) +
                3 * (Number(arrJMBG[4]) + Number(arrJMBG[10])) +
                2 * (Number(arrJMBG[5]) + Number(arrJMBG[11]))) %
                11);
        if (controlNumber > 9) {
            controlNumber = 0;
        }

        if (controlNumber !== Number(arrJMBG[12])) {
            return false;
        }

        return true;
    }
    return false;
}

export function checkSetValidBirthdateByNationalIdentificationNumber(
    nationalIdentificationNumber: string,
): Date | null {
    if (nationalIdentificationNumber.length >= 7) {
        let dateStringFull = '';
        const dateStringDay = nationalIdentificationNumber.substring(0, 2);
        const dateStringMonth = nationalIdentificationNumber.substring(2, 4);
        const dateStringYear = nationalIdentificationNumber.substring(4, 7);
        const genZNumberIdentifier = nationalIdentificationNumber.substring(4, 5);

        try {
            if (Number.parseInt(genZNumberIdentifier) === 0) {
                dateStringFull = dateStringMonth + '.' + dateStringDay + '.' + '2' + dateStringYear;
            } else {
                dateStringFull = dateStringMonth + '.' + dateStringDay + '.' + '1' + dateStringYear;
            }

            const dateAsNumber = Date.parse(dateStringFull);
            if (dateAsNumber > 0) {
                return new Date(dateAsNumber);
            }
        } catch (ex) {
            //unparsable number -> log
            console.error(ex);
            return null;
        }
    }
    return null;
}
