import { PayoutInformationDetailsDto } from './payout-information-details.dto';

export interface PayoutInformationDto {
    type: string | null;
    details: PayoutInformationDetailsDto | null;
}

export function mapPayoutInformationDto(dto: PayoutInformationDto): PayoutInformationDto {
    return {
        type: dto.type,
        details:
            dto.details != null ? (JSON.parse(dto.details as never as string) as PayoutInformationDetailsDto) : null,
    };
}
