import { Route } from '@angular/router';
import { financeRoutes } from '@nuis/finance';

export const appRoutes: Route[] = [
    {
        path: '',
        ...financeRoutes,
    },
    {
        path: 'no-access',
        loadComponent: () => import('@nuis/common').then((c) => c.NoAccessComponent),
    },
    {
        path: '404',
        loadComponent: () => import('@nuis/common').then((c) => c.PageNotFoundComponent),
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
