import { NgClass } from '@angular/common';
import { Component, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { showErrors } from '@nuis/common';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectOption } from '../models';
import { FormErrorComponent } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-multi-select',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        MultiSelectModule,
        FormLabelComponent,
        FormErrorComponent,
        FormHintComponent,
    ],
    styles: `
        :host {
            display: block;
            min-width: 1px; // NOTE: Fix overflow issue in flex wrappers
        }

        ::ng-deep {
            // NOTE: Fix for the placeholder label not showing when the control is empty
            .p-multiselect-label-empty {
                visibility: visible;
                opacity: 0.6;
            }
        }

        .invalid ::ng-deep {
            .p-multiselect {
                border-color: #ff3d32; // red-500
            }
        }
        .invalid.p-inputwrapper-focus ::ng-deep {
            .p-multiselect {
                box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <p-multiSelect
                styleClass="w-full"
                [ngClass]="{ invalid: showErrors(control()) }"
                [formControl]="control()"
                [options]="options()"
                optionLabel="label"
                optionValue="value"
                [loading]="isLoading()"
                [filter]="options().length > 5"
                [showClear]="showClear()"
                appendTo="body">
                <ng-template pTemplate="selectedItems">
                    <div class="truncate">
                        @if (selectedOptionsText(); as selectedOptionsText) {
                            {{ selectedOptionsText }}
                        } @else {
                            @if (placeholder() !== null) {
                                {{ placeholder() }}
                            } @else {
                                &nbsp;
                            }
                        }
                    </div>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                    <div class="truncate">
                        {{ option(item).label }}
                    </div>
                </ng-template>
            </p-multiSelect>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputMultiSelectComponent<T> {
    private readonly destroyRef = inject(DestroyRef);

    public label = input<string | null>(null);
    public control = input.required<FormControl<T[] | null>>();
    public options = input.required<SelectOption<T>[]>();
    public isLoading = input<boolean>(false);
    public showClear = input<boolean>(true);
    public hint = input<string | null>(null);
    public placeholder = input<string | null>(null);

    protected selectedOptionsText = signal<string | null>(null);

    protected showErrors = showErrors;
    protected option = (item: unknown) => item as SelectOption<T>;

    public init = explicitEffect([this.control], ([control]) => {
        this.setSelectedOptionsText(control.getRawValue());

        control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((items) => {
            this.setSelectedOptionsText(items);
        });
    });

    private setSelectedOptionsText(items: T[] | null) {
        const selected = this.options().filter((option) => items?.includes(option.value));
        const text = selected.length > 0 ? selected.map((option) => option.label).join(', ') : null;
        this.selectedOptionsText.set(text);
    }
}
