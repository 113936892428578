<div class="flex flex-column gap-3 h-full overflow-hidden">
    <nuis-audits-filter [isLoading]="isLoading()" [form]="filterForm" [fields]="fields()" (clear)="clearFilter()" />

    <!-- Added appendOnly: true to virtualScrollOptions to prevent Issue with scrolling when different height of columns -->
    <p-table
        class="flex-1"
        styleClass="p-datatable-gridlines p-datatable-sm"
        [style]="{ 'overflow-wrap': 'anywhere' }"
        [loading]="isLoading()"
        [value]="filteredAudits()"
        sortField="date"
        [sortOrder]="-1"
        [scrollable]="true"
        [rowTrackBy]="trackByIndex"
        scrollHeight="flex"
        scrollDirection="both"
        responsiveLayout="scroll"
        [virtualScroll]="true"
        [virtualScrollItemSize]="36"
        [virtualScrollOptions]="{ appendOnly: true }"
        rowGroupMode="rowspan"
        groupRowsBy="date">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="date">{{ 'date' | translate }} <p-sortIcon field="date"></p-sortIcon></th>
                <th>{{ 'field' | translate }}</th>
                <th>{{ 'old' | translate }}</th>
                <th>{{ 'new' | translate }}</th>
                <th>{{ 'author' | translate }}</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
            <tr>
                @if (rowgroup) {
                    <td [attr.rowspan]="rowspan">
                        {{ audit(item).date | date: 'dd.MM.yyyy, HH:mm:ss' }}
                    </td>
                }

                <td>
                    {{ fieldLabels().get(audit(item).field) ?? audit(item).field }}
                </td>
                <td style="max-width: 15rem">
                    {{ audit(item).old }}
                </td>
                <td style="max-width: 15rem">
                    {{ audit(item).new }}
                </td>
                <td class="min-w-10">
                    {{ audit(item).author | partnerId | translate }}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td class="text-center" colspan="5">{{ 'noAuditsFound' | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
