import { Component, computed, input, output } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ButtonModule } from 'primeng/button';
import { fullName } from '../../utils';
import { CardComponent } from '../card';
import { DisplayDateComponent, DisplayTextComponent } from '../displays';

@Component({
    selector: 'nuis-common-person-card',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, CardComponent, DisplayDateComponent, DisplayTextComponent],
    templateUrl: './person-card.component.html',
})
export class CommonPersonCardComponent {
    public person = input.required<{
        firstName: string | null;
        lastName: string | null;
        dateOfBirth: DateTime | null;
        nationalIdentificationNumber: string | null;
    } | null>();

    public showEditButton = input<boolean>(true);
    public showRemoveButton = input<boolean>(true);

    public edit = output<void>();
    public remove = output<void>();

    protected fullName = computed<string | null>(() => fullName(this.person()));
}
