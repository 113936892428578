import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '@nuis/common';

export function withDocumentValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['fileNameExists', (label) => translate.instant('validation.fileNameExists', { label: label })],
            [
                'fileNameCheckingFailed',
                (label) => translate.instant('validation.fileNameCheckingFailed', { label: label }),
            ],
        ]);
    };
}
