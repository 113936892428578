import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { OverviewDto } from '../../dtos/overview.dto';

@Injectable({
    providedIn: 'root',
})
export class GenderApiService {
    private readonly translate = inject(TranslateService);

    public getGenders(): Observable<OverviewDto[]> {
        return of([
            { key: 'Unknown', name: this.translate.instant('genders.unknown'), color: null },
            { key: 'Female', name: this.translate.instant('genders.female'), color: null },
            { key: 'Male', name: this.translate.instant('genders.male'), color: null },
            { key: 'Company', name: this.translate.instant('genders.company'), color: null },
        ]);
    }
}
