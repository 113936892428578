import { Component, inject, input, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    FileApiService,
    FileCardComponent,
    FileReferenceDto,
    FileSelectorComponent,
    FileUpload,
    FileUploadProgressComponent,
    showErrors,
} from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { FormErrorComponent } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-upload',
    standalone: true,
    imports: [
        ButtonModule,
        FormLabelComponent,
        FormErrorComponent,
        FileSelectorComponent,
        FileUploadProgressComponent,
        FileCardComponent,
        FormHintComponent,
    ],
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            @if (control(); as control) {
                @if (control.value !== null) {
                    <nuis-file-card [name]="control.value.displayName" [size]="control.value.size">
                        <ng-template #actions>
                            <p-button [text]="true" icon="pi pi-times" severity="secondary" (onClick)="clear()" />
                        </ng-template>
                    </nuis-file-card>
                } @else {
                    @if (fileUpload(); as fileUpload) {
                        <nuis-file-upload-progress [upload]="fileUpload" />
                    } @else {
                        <nuis-file-selector mode="single" (filesSelected)="fileSelected($event)" />
                    }
                }
            }

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputUploadComponent {
    private readonly fileApiService = inject(FileApiService);

    public label = input<string | null>(null);
    public control = input.required<FormControl<FileReferenceDto | null>>();
    public hint = input<string | null>(null);

    protected fileUpload = signal<FileUpload<FileReferenceDto> | null>(null);

    protected showErrors = showErrors;

    protected fileSelected(files: File[]) {
        const fileUpload = this.fileUpload();
        if (fileUpload != null || files.length === 0) {
            return;
        }

        const upload = new FileUpload({
            file: files[0],
            handleUpload: ({ correlationId, file }) => {
                return this.fileApiService.upload(correlationId, file.name, file);
            },
            handleCancel: () => {
                this.fileUpload.set(null);
            },
            handleComplete: (dto) => {
                this.fileUpload.set(null);
                this.valueChange(dto);
            },
        });

        this.fileUpload.set(upload);
        this.valueChange(null);
        this.control().markAsPending();

        upload.start();
    }

    protected clear() {
        this.fileUpload.set(null);
        this.valueChange(null);
    }

    private valueChange(value: FileReferenceDto | null) {
        this.control().setValue(value);
        this.control().markAsTouched();
        this.control().markAsDirty();
    }
}
