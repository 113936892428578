import { Component, computed, input, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { OverviewDto } from '@nuis/common';
import { InputMultiSelectComponent, provideFormConfig, SelectOption } from '@nuis/forms';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { AuditsFilterForm } from './audits-filter.form';

@Component({
    selector: 'nuis-audits-filter',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, TooltipModule, InputMultiSelectComponent],
    templateUrl: './audits-filter.component.html',
    providers: [...provideFormConfig({ showOptionalLabel: false })],
})
export class AuditsFilterComponent {
    public isLoading = input.required<boolean>();
    public form = input.required<FormGroup<AuditsFilterForm>>();
    public fields = input.required<OverviewDto[]>();

    public clear = output<void>();

    protected fieldOptions = computed<SelectOption<string>[]>(() => {
        return this.fields().map((type) => ({ value: type.key, label: type.name }));
    });
}
