import { inject } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrEmpty, ValidationMessageFn, ValidationMessageResolver } from '@nuis/common';
import { NoteForm } from './note-form.type';

export const eitherSubjectOrBodyValidator: ValidatorFn = (control) => {
    if (!(control instanceof FormGroup)) {
        return null;
    }

    const form = control as FormGroup<NoteForm>;
    return isNullOrEmpty(form.value.subject) && isNullOrEmpty(form.value.body)
        ? { notesEitherSubjectOrBody: true }
        : null;
};

export function withNotesValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['notesEitherSubjectOrBody', () => translate.instant('validation.notesEitherSubjectOrBody')],
        ]);
    };
}
