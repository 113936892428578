import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { showErrors } from '@nuis/common';
import { DropdownModule } from 'primeng/dropdown';
import { SelectOption } from '../models';
import { FormErrorComponent } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-single-select',
    standalone: true,
    imports: [NgClass, ReactiveFormsModule, DropdownModule, FormLabelComponent, FormErrorComponent, FormHintComponent],
    styles: `
        :host {
            display: block;
            min-width: 1px; // NOTE: Fix overflow issue in flex wrappers
        }

        .invalid ::ng-deep {
            .p-dropdown {
                border-color: #ff3d32; // red-500
            }

            .p-inputwrapper-focus {
                box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <p-dropdown
                styleClass="w-full"
                [ngClass]="{ invalid: showErrors(control()) }"
                [formControl]="control()"
                [options]="options()"
                optionLabel="label"
                optionValue="value"
                [loading]="isLoading()"
                [filter]="options().length > 5"
                [showClear]="showClear()"
                [placeholder]="placeholder() ?? undefined"
                appendTo="body">
                <ng-template pTemplate="selectedItem" let-item>
                    <div class="truncate">
                        {{ option(item).label }}
                    </div>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                    <div class="truncate">
                        {{ option(item).label }}
                    </div>
                </ng-template>
            </p-dropdown>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputSingleSelectComponent<T> {
    public label = input<string | null>(null);
    public control = input.required<FormControl<T | null>>();
    public options = input.required<SelectOption<T>[]>();
    public isLoading = input<boolean>(false);
    public showClear = input<boolean>(true);
    public hint = input<string | null>(null);
    public placeholder = input<string | null>(null);

    protected showErrors = showErrors;
    protected option = (item: unknown) => item as SelectOption<T>;
}
