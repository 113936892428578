import { AuditModel } from '../audits';

export interface AuditsDto {
    foundRecords: number;
    auditRecords: AuditDto[];
}

interface AuditDto {
    date: Date;
    author: string;
    entityType: string;
    action: string;
    updates: {
        field: string;
        oldValue: string;
        newValue: string;
    }[];
}

export function mapAuditsDto(dto: AuditsDto): AuditModel[] {
    return dto.auditRecords.flatMap((audit) =>
        audit.updates.map((update) => ({
            date: audit.date,
            author: audit.author,
            field: update.field,
            new: update.newValue,
            old: update.oldValue,
        })),
    );
}
