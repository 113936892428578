import { Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { injectValidationMessages } from '@nuis/common';

@Component({
    selector: 'nuis-form-error',
    standalone: true,
    imports: [],
    template: `
        <div class="flex flex-column gap-2 text-sm text-red-500">
            @for (error of errorMessages(); track error) {
                <div>{{ error }}</div>
            }
        </div>
    `,
})
export class FormErrorComponent implements OnInit {
    private readonly validationMessages = injectValidationMessages();
    private readonly translate = inject(TranslateService);
    private readonly destroyRef = inject(DestroyRef);

    public label = input.required<string | null>();
    public control = input.required<AbstractControl>();

    protected errorMessages = signal<string[]>([]);

    public ngOnInit() {
        const errorMessages = this.getErrorMessages();
        this.errorMessages.set(errorMessages);

        this.control()
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const errorMessages = this.getErrorMessages();
                this.errorMessages.set(errorMessages);
            });
    }

    private getErrorMessages() {
        const label = this.label() ?? '';
        const control = this.control();

        return Object.entries(control.errors ?? {}).map(([key, value]) => {
            const validationMessage = this.validationMessages.get(key);
            if (validationMessage == null) {
                return this.translate.instant('validation.unknown');
            }

            return validationMessage(label, value);
        });
    }
}
