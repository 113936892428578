import { NgClass } from '@angular/common';
import { Component, inject, input, signal } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { showErrors } from '@nuis/common';
import { FORM_LABEL_CONFIG } from '../models/form-config';

@Component({
    selector: 'nuis-form-label',
    standalone: true,
    imports: [NgClass, TranslatePipe],
    template: `
        <label class="flex gap-1 font-medium text-600" [ngClass]="{ 'text-red-500': showErrors(control()) }">
            {{ label() }}

            @if (showOptionalLabel() && isOptional()) {
                <div class="text-400">{{ 'form.optional' | translate }}</div>
            }
        </label>
    `,
})
export class FormLabelComponent {
    private readonly config = inject(FORM_LABEL_CONFIG, { optional: true });

    public label = input.required<string>();
    public control = input.required<AbstractControl>();

    protected showOptionalLabel = signal<boolean>(this.config?.showOptionalLabel ?? true);

    protected showErrors = showErrors;

    protected isOptional(): boolean {
        return !this.control().hasValidator(Validators.required);
    }
}
