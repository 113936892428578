<div class="flex flex-wrap gap-3">
    <nuis-input-multi-select
        class="w-20rem"
        [label]="'field' | translate"
        [control]="form().controls.fields"
        [options]="fieldOptions()"
        [isLoading]="isLoading()" />

    <p-button
        class="align-self-end"
        [text]="true"
        icon="pi pi-filter-slash"
        [pTooltip]="'tooltip.clearFilters' | translate"
        (onClick)="clear.emit()" />
</div>
