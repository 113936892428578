import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { showErrors } from '@nuis/common';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormErrorComponent } from './form-error.component';
import { FormHintComponent } from './form-hint.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-currency',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        InputNumberModule,
        InputGroupModule,
        InputGroupAddonModule,
        FormLabelComponent,
        FormErrorComponent,
        FormHintComponent,
    ],
    styles: `
        .invalid ::ng-deep {
            .p-inputtext {
                border-color: #ff3d32; // red-500

                &:focus {
                    box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
                }
            }
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <div class="flex gap-1">
                <p-inputNumber
                    class="flex-1"
                    styleClass="w-full"
                    inputStyleClass="text-right"
                    [ngClass]="{ invalid: showErrors(control()) }"
                    [formControl]="control()"
                    mode="decimal"
                    [minFractionDigits]="0"
                    [maxFractionDigits]="2"
                    locale="de-DE" />

                <div
                    class="align-content-center px-2 border-round text-sm surface-100 border-1 "
                    [ngClass]="control().disabled ? 'text-400 border-200' : 'text-700 border-300'">
                    {{ currency() }}
                </div>
            </div>

            @if (hint(); as hint) {
                <nuis-form-hint [hint]="hint" />
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputCurrencyComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<number | null>>();
    public currency = input.required<string>();
    public hint = input<string | null>(null);

    protected showErrors = showErrors;
}
