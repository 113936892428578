import { inject } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '../models';
import { isValidJMBG } from '../utils';

export const JMBGValidator: ValidatorFn = (control: AbstractControl<string | undefined | null>) => {
    return isValidJMBG(control.value ?? '');
};

export function withJMBGValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['jmbgBirthYearError', () => translate.instant('validation.jmbgBirthYearError')],
            ['jmbgBirthMonthError', () => translate.instant('validation.jmbgBirthMonthError')],
            ['jmbgBirthDayError', () => translate.instant('validation.jmbgBirthDayError')],
            ['jmbgControlNumberError', () => translate.instant('validation.jmbgControlNumberError')],
            ['jmbgLengthError', () => translate.instant('validation.jmbgLengthError')],
        ]);
    };
}
