export function fullName(
    person:
        | {
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
          }
        | null
        | undefined,
): string {
    return [person?.firstName ?? '', person?.lastName ?? ''].join(' ');
}
