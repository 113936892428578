import { DatePipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, input, OnInit, signal, TrackByFunction } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { PartnerIdPipe } from '@luis/common/shared';
import { TranslatePipe } from '@ngx-translate/core';
import { OverviewDto, toLabelMap } from '@nuis/common';
import { TableModule } from 'primeng/table';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AuditModel } from './audit.model';
import { AuditsFilterComponent } from './audits-filter/audits-filter.component';
import { initAuditsFilterForm } from './audits-filter/audits-filter.form';

@Component({
    selector: 'nuis-audit-list',
    standalone: true,
    imports: [TranslatePipe, PartnerIdPipe, TableModule, DatePipe, AuditsFilterComponent],
    templateUrl: './audits.component.html',
})
export class AuditsComponent implements OnInit {
    private readonly fb = inject(NonNullableFormBuilder);
    private readonly destroyRef = inject(DestroyRef);

    public isLoading = input.required<boolean>();
    public audits = input.required<AuditModel[]>();
    public allFields = input.required<OverviewDto[]>();

    protected filterForm = initAuditsFilterForm(this.fb);
    protected filterFields = signal<string[] | null>(null);

    protected filteredAudits = computed<AuditModel[]>(() => {
        const filter = this.filterFields() ?? [];
        return filter.length > 0 ? this.audits().filter((audit) => filter.includes(audit.field)) : this.audits();
    });

    protected fields = computed<OverviewDto[]>(() => {
        const keys = this.audits().map((audit) => audit.field);
        return this.allFields().filter((f) => keys.includes(f.key));
    });

    protected fieldLabels = computed<Map<string, string>>(() => {
        return toLabelMap(this.fields());
    });

    public ngOnInit() {
        this.filterForm.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.filterFields.set(this.filterForm.getRawValue().fields);
            });
    }

    protected trackByIndex: TrackByFunction<AuditModel> = (index: number): number => index;
    protected audit = (item: unknown): AuditModel => item as AuditModel;

    protected clearFilter() {
        this.filterForm.reset();
    }
}
