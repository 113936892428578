import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { NoteDto } from '@nuis/common';
import { eitherSubjectOrBodyValidator } from './note-validator.util';

export interface NoteForm {
    subject: FormControl<string>;
    body: FormControl<string>;
}

export function initNoteForm(fb: NonNullableFormBuilder, note: NoteDto | null): FormGroup<NoteForm> {
    return fb.group<NoteForm>(
        {
            subject: fb.control<string>(note?.subject ?? '', [Validators.maxLength(250)]),
            body: fb.control<string>(note?.body ?? '', [Validators.maxLength(2000)]),
        },
        {
            validators: [eitherSubjectOrBodyValidator],
        },
    );
}
