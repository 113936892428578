import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { CountryDto } from '../../dtos/country.dto';

@Injectable({
    providedIn: 'root',
})
export class CountryApiService {
    private readonly translate = inject(TranslateService);

    public getCountries(): Observable<CountryDto[]> {
        const countries: CountryDto[] = [
            { id: 'af', isoCode3: 'afg', name: this.translate.instant('countryNames.af') },
            { id: 'ax', isoCode3: 'ala', name: this.translate.instant('countryNames.ax') },
            { id: 'al', isoCode3: 'alb', name: this.translate.instant('countryNames.al') },
            { id: 'dz', isoCode3: 'dza', name: this.translate.instant('countryNames.dz') },
            { id: 'as', isoCode3: 'asm', name: this.translate.instant('countryNames.as') },
            { id: 'ad', isoCode3: 'and', name: this.translate.instant('countryNames.ad') },
            { id: 'ao', isoCode3: 'ago', name: this.translate.instant('countryNames.ao') },
            { id: 'ai', isoCode3: 'aia', name: this.translate.instant('countryNames.ai') },
            { id: 'aq', isoCode3: 'ata', name: this.translate.instant('countryNames.aq') },
            { id: 'ag', isoCode3: 'atg', name: this.translate.instant('countryNames.ag') },
            { id: 'ar', isoCode3: 'arg', name: this.translate.instant('countryNames.ar') },
            { id: 'am', isoCode3: 'arm', name: this.translate.instant('countryNames.am') },
            { id: 'aw', isoCode3: 'abw', name: this.translate.instant('countryNames.aw') },
            { id: 'au', isoCode3: 'aus', name: this.translate.instant('countryNames.au') },
            { id: 'at', isoCode3: 'aut', name: this.translate.instant('countryNames.at') },
            { id: 'az', isoCode3: 'aze', name: this.translate.instant('countryNames.az') },
            { id: 'bs', isoCode3: 'bhs', name: this.translate.instant('countryNames.bs') },
            { id: 'bh', isoCode3: 'bhr', name: this.translate.instant('countryNames.bh') },
            { id: 'bd', isoCode3: 'bgd', name: this.translate.instant('countryNames.bd') },
            { id: 'bb', isoCode3: 'brb', name: this.translate.instant('countryNames.bb') },
            { id: 'by', isoCode3: 'blr', name: this.translate.instant('countryNames.by') },
            { id: 'be', isoCode3: 'bel', name: this.translate.instant('countryNames.be') },
            { id: 'bz', isoCode3: 'blz', name: this.translate.instant('countryNames.bz') },
            { id: 'bj', isoCode3: 'ben', name: this.translate.instant('countryNames.bj') },
            { id: 'bm', isoCode3: 'bmu', name: this.translate.instant('countryNames.bm') },
            { id: 'bt', isoCode3: 'btn', name: this.translate.instant('countryNames.bt') },
            { id: 'bo', isoCode3: 'bol', name: this.translate.instant('countryNames.bo') },
            { id: 'bq', isoCode3: 'bes', name: this.translate.instant('countryNames.bq') },
            { id: 'ba', isoCode3: 'bih', name: this.translate.instant('countryNames.ba') },
            { id: 'bw', isoCode3: 'bwa', name: this.translate.instant('countryNames.bw') },
            { id: 'bv', isoCode3: 'bvt', name: this.translate.instant('countryNames.bv') },
            { id: 'br', isoCode3: 'bra', name: this.translate.instant('countryNames.br') },
            { id: 'io', isoCode3: 'iot', name: this.translate.instant('countryNames.io') },
            { id: 'bn', isoCode3: 'brn', name: this.translate.instant('countryNames.bn') },
            { id: 'bg', isoCode3: 'bgr', name: this.translate.instant('countryNames.bg') },
            { id: 'bf', isoCode3: 'bfa', name: this.translate.instant('countryNames.bf') },
            { id: 'bi', isoCode3: 'bdi', name: this.translate.instant('countryNames.bi') },
            { id: 'cv', isoCode3: 'cpv', name: this.translate.instant('countryNames.cv') },
            { id: 'kh', isoCode3: 'khm', name: this.translate.instant('countryNames.kh') },
            { id: 'cm', isoCode3: 'cmr', name: this.translate.instant('countryNames.cm') },
            { id: 'ca', isoCode3: 'can', name: this.translate.instant('countryNames.ca') },
            { id: 'ky', isoCode3: 'cym', name: this.translate.instant('countryNames.ky') },
            { id: 'cf', isoCode3: 'caf', name: this.translate.instant('countryNames.cf') },
            { id: 'td', isoCode3: 'tcd', name: this.translate.instant('countryNames.td') },
            { id: 'cl', isoCode3: 'chl', name: this.translate.instant('countryNames.cl') },
            { id: 'cn', isoCode3: 'chn', name: this.translate.instant('countryNames.cn') },
            { id: 'cx', isoCode3: 'cxr', name: this.translate.instant('countryNames.cx') },
            { id: 'cc', isoCode3: 'cck', name: this.translate.instant('countryNames.cc') },
            { id: 'co', isoCode3: 'col', name: this.translate.instant('countryNames.co') },
            { id: 'km', isoCode3: 'com', name: this.translate.instant('countryNames.km') },
            { id: 'cg', isoCode3: 'cog', name: this.translate.instant('countryNames.cg') },
            { id: 'cd', isoCode3: 'cod', name: this.translate.instant('countryNames.cd') },
            { id: 'ck', isoCode3: 'cok', name: this.translate.instant('countryNames.ck') },
            { id: 'cr', isoCode3: 'cri', name: this.translate.instant('countryNames.cr') },
            { id: 'ci', isoCode3: 'civ', name: this.translate.instant('countryNames.ci') },
            { id: 'hr', isoCode3: 'hrv', name: this.translate.instant('countryNames.hr') },
            { id: 'cu', isoCode3: 'cub', name: this.translate.instant('countryNames.cu') },
            { id: 'cw', isoCode3: 'cuw', name: this.translate.instant('countryNames.cw') },
            { id: 'cy', isoCode3: 'cyp', name: this.translate.instant('countryNames.cy') },
            { id: 'cz', isoCode3: 'cze', name: this.translate.instant('countryNames.cz') },
            { id: 'dk', isoCode3: 'dnk', name: this.translate.instant('countryNames.dk') },
            { id: 'dj', isoCode3: 'dji', name: this.translate.instant('countryNames.dj') },
            { id: 'dm', isoCode3: 'dma', name: this.translate.instant('countryNames.dm') },
            { id: 'do', isoCode3: 'dom', name: this.translate.instant('countryNames.do') },
            { id: 'ec', isoCode3: 'ecu', name: this.translate.instant('countryNames.ec') },
            { id: 'eg', isoCode3: 'egy', name: this.translate.instant('countryNames.eg') },
            { id: 'sv', isoCode3: 'slv', name: this.translate.instant('countryNames.sv') },
            { id: 'gq', isoCode3: 'gnq', name: this.translate.instant('countryNames.gq') },
            { id: 'er', isoCode3: 'eri', name: this.translate.instant('countryNames.er') },
            { id: 'ee', isoCode3: 'est', name: this.translate.instant('countryNames.ee') },
            { id: 'sz', isoCode3: 'swz', name: this.translate.instant('countryNames.sz') },
            { id: 'et', isoCode3: 'eth', name: this.translate.instant('countryNames.et') },
            { id: 'fk', isoCode3: 'flk', name: this.translate.instant('countryNames.fk') },
            { id: 'fo', isoCode3: 'fro', name: this.translate.instant('countryNames.fo') },
            { id: 'fj', isoCode3: 'fji', name: this.translate.instant('countryNames.fj') },
            { id: 'fi', isoCode3: 'fin', name: this.translate.instant('countryNames.fi') },
            { id: 'fr', isoCode3: 'fra', name: this.translate.instant('countryNames.fr') },
            { id: 'gf', isoCode3: 'guf', name: this.translate.instant('countryNames.gf') },
            { id: 'pf', isoCode3: 'pyf', name: this.translate.instant('countryNames.pf') },
            { id: 'tf', isoCode3: 'atf', name: this.translate.instant('countryNames.tf') },
            { id: 'ga', isoCode3: 'gab', name: this.translate.instant('countryNames.ga') },
            { id: 'gm', isoCode3: 'gmb', name: this.translate.instant('countryNames.gm') },
            { id: 'ge', isoCode3: 'geo', name: this.translate.instant('countryNames.ge') },
            { id: 'de', isoCode3: 'deu', name: this.translate.instant('countryNames.de') },
            { id: 'gh', isoCode3: 'gha', name: this.translate.instant('countryNames.gh') },
            { id: 'gi', isoCode3: 'gib', name: this.translate.instant('countryNames.gi') },
            { id: 'gr', isoCode3: 'grc', name: this.translate.instant('countryNames.gr') },
            { id: 'gl', isoCode3: 'grl', name: this.translate.instant('countryNames.gl') },
            { id: 'gd', isoCode3: 'grd', name: this.translate.instant('countryNames.gd') },
            { id: 'gp', isoCode3: 'glp', name: this.translate.instant('countryNames.gp') },
            { id: 'gu', isoCode3: 'gum', name: this.translate.instant('countryNames.gu') },
            { id: 'gt', isoCode3: 'gtm', name: this.translate.instant('countryNames.gt') },
            { id: 'gg', isoCode3: 'ggy', name: this.translate.instant('countryNames.gg') },
            { id: 'gn', isoCode3: 'gin', name: this.translate.instant('countryNames.gn') },
            { id: 'gw', isoCode3: 'gnb', name: this.translate.instant('countryNames.gw') },
            { id: 'gy', isoCode3: 'guy', name: this.translate.instant('countryNames.gy') },
            { id: 'ht', isoCode3: 'hti', name: this.translate.instant('countryNames.ht') },
            { id: 'hm', isoCode3: 'hmd', name: this.translate.instant('countryNames.hm') },
            { id: 'va', isoCode3: 'vat', name: this.translate.instant('countryNames.va') },
            { id: 'hn', isoCode3: 'hnd', name: this.translate.instant('countryNames.hn') },
            { id: 'hk', isoCode3: 'hkg', name: this.translate.instant('countryNames.hk') },
            { id: 'hu', isoCode3: 'hun', name: this.translate.instant('countryNames.hu') },
            { id: 'is', isoCode3: 'isl', name: this.translate.instant('countryNames.is') },
            { id: 'in', isoCode3: 'ind', name: this.translate.instant('countryNames.in') },
            { id: 'id', isoCode3: 'idn', name: this.translate.instant('countryNames.id') },
            { id: 'ir', isoCode3: 'irn', name: this.translate.instant('countryNames.ir') },
            { id: 'iq', isoCode3: 'irq', name: this.translate.instant('countryNames.iq') },
            { id: 'ie', isoCode3: 'irl', name: this.translate.instant('countryNames.ie') },
            { id: 'im', isoCode3: 'imn', name: this.translate.instant('countryNames.im') },
            { id: 'il', isoCode3: 'isr', name: this.translate.instant('countryNames.il') },
            { id: 'it', isoCode3: 'ita', name: this.translate.instant('countryNames.it') },
            { id: 'jm', isoCode3: 'jam', name: this.translate.instant('countryNames.jm') },
            { id: 'jp', isoCode3: 'jpn', name: this.translate.instant('countryNames.jp') },
            { id: 'je', isoCode3: 'jey', name: this.translate.instant('countryNames.je') },
            { id: 'jo', isoCode3: 'jor', name: this.translate.instant('countryNames.jo') },
            { id: 'kz', isoCode3: 'kaz', name: this.translate.instant('countryNames.kz') },
            { id: 'ke', isoCode3: 'ken', name: this.translate.instant('countryNames.ke') },
            { id: 'ki', isoCode3: 'kir', name: this.translate.instant('countryNames.ki') },
            { id: 'kp', isoCode3: 'prk', name: this.translate.instant('countryNames.kp') },
            { id: 'kr', isoCode3: 'kor', name: this.translate.instant('countryNames.kr') },
            { id: 'kw', isoCode3: 'kwt', name: this.translate.instant('countryNames.kw') },
            { id: 'kg', isoCode3: 'kgz', name: this.translate.instant('countryNames.kg') },
            { id: 'la', isoCode3: 'lao', name: this.translate.instant('countryNames.la') },
            { id: 'lv', isoCode3: 'lva', name: this.translate.instant('countryNames.lv') },
            { id: 'lb', isoCode3: 'lbn', name: this.translate.instant('countryNames.lb') },
            { id: 'ls', isoCode3: 'lso', name: this.translate.instant('countryNames.ls') },
            { id: 'lr', isoCode3: 'lbr', name: this.translate.instant('countryNames.lr') },
            { id: 'ly', isoCode3: 'lby', name: this.translate.instant('countryNames.ly') },
            { id: 'li', isoCode3: 'lie', name: this.translate.instant('countryNames.li') },
            { id: 'lt', isoCode3: 'ltu', name: this.translate.instant('countryNames.lt') },
            { id: 'lu', isoCode3: 'lux', name: this.translate.instant('countryNames.lu') },
            { id: 'mo', isoCode3: 'mac', name: this.translate.instant('countryNames.mo') },
            { id: 'mg', isoCode3: 'mdg', name: this.translate.instant('countryNames.mg') },
            { id: 'mw', isoCode3: 'mwi', name: this.translate.instant('countryNames.mw') },
            { id: 'my', isoCode3: 'mys', name: this.translate.instant('countryNames.my') },
            { id: 'mv', isoCode3: 'mdv', name: this.translate.instant('countryNames.mv') },
            { id: 'ml', isoCode3: 'mli', name: this.translate.instant('countryNames.ml') },
            { id: 'mt', isoCode3: 'mlt', name: this.translate.instant('countryNames.mt') },
            { id: 'mh', isoCode3: 'mhl', name: this.translate.instant('countryNames.mh') },
            { id: 'mq', isoCode3: 'mtq', name: this.translate.instant('countryNames.mq') },
            { id: 'mr', isoCode3: 'mrt', name: this.translate.instant('countryNames.mr') },
            { id: 'mu', isoCode3: 'mus', name: this.translate.instant('countryNames.mu') },
            { id: 'yt', isoCode3: 'myt', name: this.translate.instant('countryNames.yt') },
            { id: 'mx', isoCode3: 'mex', name: this.translate.instant('countryNames.mx') },
            { id: 'fm', isoCode3: 'fsm', name: this.translate.instant('countryNames.fm') },
            { id: 'md', isoCode3: 'mda', name: this.translate.instant('countryNames.md') },
            { id: 'mc', isoCode3: 'mco', name: this.translate.instant('countryNames.mc') },
            { id: 'mn', isoCode3: 'mng', name: this.translate.instant('countryNames.mn') },
            { id: 'me', isoCode3: 'mne', name: this.translate.instant('countryNames.me') },
            { id: 'ms', isoCode3: 'msr', name: this.translate.instant('countryNames.ms') },
            { id: 'ma', isoCode3: 'mar', name: this.translate.instant('countryNames.ma') },
            { id: 'mz', isoCode3: 'moz', name: this.translate.instant('countryNames.mz') },
            { id: 'mm', isoCode3: 'mmr', name: this.translate.instant('countryNames.mm') },
            { id: 'na', isoCode3: 'nam', name: this.translate.instant('countryNames.na') },
            { id: 'nr', isoCode3: 'nru', name: this.translate.instant('countryNames.nr') },
            { id: 'np', isoCode3: 'npl', name: this.translate.instant('countryNames.np') },
            { id: 'nl', isoCode3: 'nld', name: this.translate.instant('countryNames.nl') },
            { id: 'nc', isoCode3: 'ncl', name: this.translate.instant('countryNames.nc') },
            { id: 'nz', isoCode3: 'nzl', name: this.translate.instant('countryNames.nz') },
            { id: 'ni', isoCode3: 'nic', name: this.translate.instant('countryNames.ni') },
            { id: 'ne', isoCode3: 'ner', name: this.translate.instant('countryNames.ne') },
            { id: 'ng', isoCode3: 'nga', name: this.translate.instant('countryNames.ng') },
            { id: 'nu', isoCode3: 'niu', name: this.translate.instant('countryNames.nu') },
            { id: 'nf', isoCode3: 'nfk', name: this.translate.instant('countryNames.nf') },
            { id: 'mk', isoCode3: 'mkd', name: this.translate.instant('countryNames.mk') },
            { id: 'mp', isoCode3: 'mnp', name: this.translate.instant('countryNames.mp') },
            { id: 'no', isoCode3: 'nor', name: this.translate.instant('countryNames.no') },
            { id: 'om', isoCode3: 'omn', name: this.translate.instant('countryNames.om') },
            { id: 'pk', isoCode3: 'pak', name: this.translate.instant('countryNames.pk') },
            { id: 'pw', isoCode3: 'plw', name: this.translate.instant('countryNames.pw') },
            { id: 'ps', isoCode3: 'pse', name: this.translate.instant('countryNames.ps') },
            { id: 'pa', isoCode3: 'pan', name: this.translate.instant('countryNames.pa') },
            { id: 'pg', isoCode3: 'png', name: this.translate.instant('countryNames.pg') },
            { id: 'py', isoCode3: 'pry', name: this.translate.instant('countryNames.py') },
            { id: 'pe', isoCode3: 'per', name: this.translate.instant('countryNames.pe') },
            { id: 'ph', isoCode3: 'phl', name: this.translate.instant('countryNames.ph') },
            { id: 'pn', isoCode3: 'pcn', name: this.translate.instant('countryNames.pn') },
            { id: 'pl', isoCode3: 'pol', name: this.translate.instant('countryNames.pl') },
            { id: 'pt', isoCode3: 'prt', name: this.translate.instant('countryNames.pt') },
            { id: 'pr', isoCode3: 'pri', name: this.translate.instant('countryNames.pr') },
            { id: 'qa', isoCode3: 'qat', name: this.translate.instant('countryNames.qa') },
            { id: 're', isoCode3: 'reu', name: this.translate.instant('countryNames.re') },
            { id: 'ro', isoCode3: 'rou', name: this.translate.instant('countryNames.ro') },
            { id: 'ru', isoCode3: 'rus', name: this.translate.instant('countryNames.ru') },
            { id: 'rw', isoCode3: 'rwa', name: this.translate.instant('countryNames.rw') },
            { id: 'bl', isoCode3: 'blm', name: this.translate.instant('countryNames.bl') },
            { id: 'sh', isoCode3: 'shn', name: this.translate.instant('countryNames.sh') },
            { id: 'kn', isoCode3: 'kna', name: this.translate.instant('countryNames.kn') },
            { id: 'lc', isoCode3: 'lca', name: this.translate.instant('countryNames.lc') },
            { id: 'mf', isoCode3: 'maf', name: this.translate.instant('countryNames.mf') },
            { id: 'pm', isoCode3: 'spm', name: this.translate.instant('countryNames.pm') },
            { id: 'vc', isoCode3: 'vct', name: this.translate.instant('countryNames.vc') },
            { id: 'ws', isoCode3: 'wsm', name: this.translate.instant('countryNames.ws') },
            { id: 'sm', isoCode3: 'smr', name: this.translate.instant('countryNames.sm') },
            { id: 'st', isoCode3: 'stp', name: this.translate.instant('countryNames.st') },
            { id: 'sa', isoCode3: 'sau', name: this.translate.instant('countryNames.sa') },
            { id: 'sn', isoCode3: 'sen', name: this.translate.instant('countryNames.sn') },
            { id: 'rs', isoCode3: 'srb', name: this.translate.instant('countryNames.rs') },
            { id: 'sc', isoCode3: 'syc', name: this.translate.instant('countryNames.sc') },
            { id: 'sl', isoCode3: 'sle', name: this.translate.instant('countryNames.sl') },
            { id: 'sg', isoCode3: 'sgp', name: this.translate.instant('countryNames.sg') },
            { id: 'sx', isoCode3: 'sxm', name: this.translate.instant('countryNames.sx') },
            { id: 'sk', isoCode3: 'svk', name: this.translate.instant('countryNames.sk') },
            { id: 'si', isoCode3: 'svn', name: this.translate.instant('countryNames.si') },
            { id: 'sb', isoCode3: 'slb', name: this.translate.instant('countryNames.sb') },
            { id: 'so', isoCode3: 'som', name: this.translate.instant('countryNames.so') },
            { id: 'za', isoCode3: 'zaf', name: this.translate.instant('countryNames.za') },
            { id: 'gs', isoCode3: 'sgs', name: this.translate.instant('countryNames.gs') },
            { id: 'ss', isoCode3: 'ssd', name: this.translate.instant('countryNames.ss') },
            { id: 'es', isoCode3: 'esp', name: this.translate.instant('countryNames.es') },
            { id: 'lk', isoCode3: 'lka', name: this.translate.instant('countryNames.lk') },
            { id: 'sd', isoCode3: 'sdn', name: this.translate.instant('countryNames.sd') },
            { id: 'sr', isoCode3: 'sur', name: this.translate.instant('countryNames.sr') },
            { id: 'sj', isoCode3: 'sjm', name: this.translate.instant('countryNames.sj') },
            { id: 'se', isoCode3: 'swe', name: this.translate.instant('countryNames.se') },
            { id: 'ch', isoCode3: 'che', name: this.translate.instant('countryNames.ch') },
            { id: 'sy', isoCode3: 'syr', name: this.translate.instant('countryNames.sy') },
            { id: 'tw', isoCode3: 'twn', name: this.translate.instant('countryNames.tw') },
            { id: 'tj', isoCode3: 'tjk', name: this.translate.instant('countryNames.tj') },
            { id: 'tz', isoCode3: 'tza', name: this.translate.instant('countryNames.tz') },
            { id: 'th', isoCode3: 'tha', name: this.translate.instant('countryNames.th') },
            { id: 'tl', isoCode3: 'tls', name: this.translate.instant('countryNames.tl') },
            { id: 'tg', isoCode3: 'tgo', name: this.translate.instant('countryNames.tg') },
            { id: 'tk', isoCode3: 'tkl', name: this.translate.instant('countryNames.tk') },
            { id: 'to', isoCode3: 'ton', name: this.translate.instant('countryNames.to') },
            { id: 'tt', isoCode3: 'tto', name: this.translate.instant('countryNames.tt') },
            { id: 'tn', isoCode3: 'tun', name: this.translate.instant('countryNames.tn') },
            { id: 'tr', isoCode3: 'tur', name: this.translate.instant('countryNames.tr') },
            { id: 'tm', isoCode3: 'tkm', name: this.translate.instant('countryNames.tm') },
            { id: 'tc', isoCode3: 'tca', name: this.translate.instant('countryNames.tc') },
            { id: 'tv', isoCode3: 'tuv', name: this.translate.instant('countryNames.tv') },
            { id: 'ug', isoCode3: 'uga', name: this.translate.instant('countryNames.ug') },
            { id: 'ua', isoCode3: 'ukr', name: this.translate.instant('countryNames.ua') },
            { id: 'ae', isoCode3: 'are', name: this.translate.instant('countryNames.ae') },
            { id: 'gb', isoCode3: 'gbr', name: this.translate.instant('countryNames.gb') },
            { id: 'us', isoCode3: 'usa', name: this.translate.instant('countryNames.us') },
            { id: 'um', isoCode3: 'umi', name: this.translate.instant('countryNames.um') },
            { id: 'uy', isoCode3: 'ury', name: this.translate.instant('countryNames.uy') },
            { id: 'uz', isoCode3: 'uzb', name: this.translate.instant('countryNames.uz') },
            { id: 'vu', isoCode3: 'vut', name: this.translate.instant('countryNames.vu') },
            { id: 've', isoCode3: 'ven', name: this.translate.instant('countryNames.ve') },
            { id: 'vn', isoCode3: 'vnm', name: this.translate.instant('countryNames.vn') },
            { id: 'vg', isoCode3: 'vgb', name: this.translate.instant('countryNames.vg') },
            { id: 'xk', isoCode3: 'xxk', name: this.translate.instant('countryNames.xk') },
            { id: 'vi', isoCode3: 'vir', name: this.translate.instant('countryNames.vi') },
            { id: 'wf', isoCode3: 'wlf', name: this.translate.instant('countryNames.wf') },
            { id: 'eh', isoCode3: 'esh', name: this.translate.instant('countryNames.eh') },
            { id: 'ye', isoCode3: 'yem', name: this.translate.instant('countryNames.ye') },
            { id: 'zm', isoCode3: 'zmb', name: this.translate.instant('countryNames.zm') },
            { id: 'zw', isoCode3: 'zwe', name: this.translate.instant('countryNames.zw') },
        ];

        return of(countries);
    }
}
