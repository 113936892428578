import { ProductCategory } from '../enums';

const productCategoryRoute: Record<ProductCategory, string> = {
    TravelInsurance: 'travel-insurance',
    Mastercard: 'mastercard-insurance',
    TravelInsuranceDrei: 'travel-drei-insurance',
};

export function mapProductCategoryToRoute(productCategory: ProductCategory): string {
    return productCategoryRoute[productCategory];
}
