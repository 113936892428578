import { Component, computed, input } from '@angular/core';
import { OverviewDto } from '../../dtos';
import { StatusColor } from '../../enums';
import { isNullOrEmpty } from '../../utils';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';

@Component({
    selector: 'nuis-display-reference',
    standalone: true,
    imports: [StatusBadgeComponent],
    template: `
        <div class="flex flex-column gap-1">
            <div class="text-sm text-500">{{ label() }}</div>
            <div>
                @if (!isNullOrEmpty(value())) {
                    @if (statusColor(); as statusColor) {
                        <nuis-status-badge [label]="displayValue()" [color]="statusColor" />
                    } @else {
                        {{ displayValue() }}
                    }
                } @else {
                    <i class="pi pi-minus"></i>
                }
            </div>
        </div>
    `,
})
export class DisplayReferenceComponent {
    public label = input.required<string>();
    public value = input.required<string | null>();
    public entities = input.required<OverviewDto[]>();

    protected displayValue = computed<string | null>(() => {
        const entity = this.entities().find((e) => e.key === this.value()) ?? null;
        return entity?.name ?? this.value();
    });

    protected statusColor = computed<StatusColor | null>(() => {
        const entity = this.entities().find((e) => e.key === this.value()) ?? null;
        return entity?.color ?? null;
    });

    protected isNullOrEmpty = isNullOrEmpty;
}
